import {
  useState,
  useEffect,
  useRef,
  useMemo,
  type MutableRefObject,
  createContext,
  type PropsWithChildren,
} from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IntersectionContextData<T extends HTMLElement = any> {
  isVisible?: boolean;
  ref?: MutableRefObject<T>;
}

const useIntersection = () => {
  const [isVisible, setState] = useState<boolean | undefined>();
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) {
      return setState(undefined);
    }

    const observer = new IntersectionObserver(([entry]) =>
      setState(entry.isIntersecting),
    );

    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref.current]);

  return useMemo(() => ({ isVisible, ref }), [isVisible, ref]);
};

export const IntersectionContext =
  createContext<IntersectionContextData | null>(null);

export const CtaIntersectionProvider: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <IntersectionContext.Provider value={useIntersection()}>
    {children}
  </IntersectionContext.Provider>
);
